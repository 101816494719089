body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  background-color: #fff;
}

h1, h2, h3 {
  font-family: 'Raleway', sans-serif;
}

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.site section {
  flex: 1;
}

.gray {
  color: rgba(255, 255, 255, 0.9);
}

.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 85%;
  padding: 12px;
}

.section-dark {
  background-color: #546e7a;
  color: #fff;
}

.section-dark a {
  color: #fff;
}

img.img {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  max-width: 100%;
  height: auto;
}

.card {
  margin: 15px 0;
  border-radius: 0 0 2px 2px;
  background-color: #546e7a;
  color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.card .card-title {
  padding: 4px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.35);
}

.card .card-content {
  color: rgba(255, 255, 255, 0.9);
  padding: 10px 20px;
}

.card a {
  color: #fff;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
}

.space-between {
  justify-content: space-between;
}

.nav {
  margin-bottom: 1px;
}

.menu a {
  margin: 0 3px;
  color: rgba(255, 255, 255, 0.9);
  flex: 1;
  display: block;
  padding: 3px 6px;
}

.menu a.active {
  color: white;
}

.spacer {
  margin-top: 12px;
}

a :hover {
  text-decoration: underline;
}

img.tt-img {
  margin: 12px;
  max-width: 100%;
  height: auto;
}
